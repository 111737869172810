import React from "react";
import "./Button.css";

interface ButtonProps {
  onClick: () => void;
  label: string;
  className?: string;
  buttonType?: "primary" | "secondary";
  minWidth?: "w-25" | "w-50" | "w-75" | "w-100";
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  label,
  className,
  buttonType = "primary",
  minWidth,
}) => {
  const baseClass = "btn ";
  const typeClass =
    buttonType === "primary" ? "custom-btn-primary" : "custom-btn-secondary";
  const customClass = className ?? "";
  const widthClass = minWidth ?? "";

  return (
    <button
      onClick={onClick}
      className={`${baseClass} ${typeClass} ${customClass} ${widthClass} rounded-4`}
    >
      <b>{label}</b>
    </button>
  );
};

export default Button;
