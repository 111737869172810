import React from "react";

interface ProductItemProps {
  icon: React.ReactNode;
  url: string;
  label: string;
}

const ProductItem: React.FC<ProductItemProps> = ({ icon, url, label }) => {
  return (
    <li className="list-item mb-2">
      {icon}
      <div className="list-item-text-container">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={url}
          className="lead"
          style={{ color: "black" }}
        >
          <span className="list-item-text">{label}</span>
        </a>
      </div>
    </li>
  );
};

export default ProductItem;
