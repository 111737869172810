import React from "react";
import { Col } from "react-bootstrap";
import profileDetailsData from "../../content/bio/profileDetails.json";

const ProfileDetails: React.FC = () => {
  return (
    <Col xs={12} className="mt-1 text-justify">
      {profileDetailsData.description.map((paragraph, index) => (
        <p key={index} className="lead">
          {paragraph}
        </p>
      ))}
    </Col>
  );
};

export default ProfileDetails;
