import React from "react";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";

const Brand: React.FC = () => {
  return (
    <Navbar.Brand as={Link} to="/" id="brand">
      <img
        alt="Re Un"
        src={`${process.env.PUBLIC_URL}/static/images/Logo_ReUn_new.svg`}
        width="90"
        height="45"
        className="rounded-2 d-inline-block"
      />
    </Navbar.Brand>
  );
};

export default Brand;
