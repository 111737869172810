import React, { useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { FaPeopleArrows, FaRegNewspaper } from "react-icons/fa";
import "./ProductsPage.css";
import Section from "./Section";
import ProductList from "./ProductList";
import articlesData from "../../content/products/articles.json";
import seriesOfGoodPracticesData from "../../content/products/seriesOfGoodPractices.json";
import cooperationsData from "../../content/products/cooperations.json";
import products from "../../content/products/products.json";

const ProductsPage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const articles = articlesData.map((article) => ({
    ...article,
    icon: <FaRegNewspaper color="black" />,
  }));

  const seriesOfGoodPractices = seriesOfGoodPracticesData.map((item) => ({
    ...item,
    icon: <FaRegNewspaper color="black" />,
  }));

  const cooperations = cooperationsData.map((cooperation) => ({
    ...cooperation,
    icon: <FaPeopleArrows color="black" />,
  }));

  return (
    <Container>
      <Row
        className="rounded-4 my-5 p-4"
        style={{ backgroundColor: "#79021C", color: "white" }}
      >
        <Col md={6}>
          <div style={{ height: "100%" }}>
            <h1 className="display-1 text-center">Products</h1>
            <p className="lead text-justify">
              {products.paragraph.split("\n\n").map((text, index) => (
                <React.Fragment key={index}>
                  {text}
                  <br />
                  <br />
                </React.Fragment>
              ))}
            </p>
          </div>
        </Col>
        <Col md={6} className="text-center p-4">
          <Image
            src={`${process.env.PUBLIC_URL}/static/images/image_3.jpg`}
            fluid
            rounded
            className="h-100"
          />
        </Col>
      </Row>

      <Section title="Article">
        <ProductList items={articles} />
      </Section>

      <Section title="Series of Good Practices">
        <ProductList items={seriesOfGoodPractices} />
      </Section>

      <Section title="Cooperations">
        <ProductList items={cooperations} />
      </Section>
    </Container>
  );
};

export default ProductsPage;
