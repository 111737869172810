import React, { useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./StrategyDevelopmentPage.css";
import TimeLine, { TimeLineItem } from "../TimeLine";
import strategyDevelopmentContent from "../../../content/services/strategy/strategyDevelopmentContent.json";

const StrategyDevelopmentPage: React.FC = () => {
  const selectedProcesses: TimeLineItem[] =
    strategyDevelopmentContent.selectedProcesses;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <Row
        className="rounded-4 my-5 p-4"
        style={{
          backgroundColor: "#F0F0F0",
        }}
      >
        <Col xs={12} lg={8}>
          <h1 className="display-3 text-center">
            {strategyDevelopmentContent.title}
          </h1>
          <ul className="lead" style={{ textAlign: "justify" }}>
            {strategyDevelopmentContent.descriptionItems.map((item, index) => (
              <li key={index} className="mb-3">
                {item}
              </li>
            ))}
          </ul>
        </Col>
        <Col xs={12} lg={4} className="text-center">
          <Image
            src={`${process.env.PUBLIC_URL}${strategyDevelopmentContent.image}`}
            fluid
            rounded
          />
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <h3 className="display-5 mb-3 text-center">
            {strategyDevelopmentContent.approachTitle}
          </h3>
          <p className="lead mb-4" style={{ textAlign: "justify" }}>
            {strategyDevelopmentContent.approachDescription}
          </p>
          <hr />
        </Col>
      </Row>

      <TimeLine
        title={strategyDevelopmentContent.selectedProcessesTitle}
        items={selectedProcesses}
      />
    </Container>
  );
};

export default StrategyDevelopmentPage;
