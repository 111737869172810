import React, { useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./ConsultingPage.css";
import TimeLine, { TimeLineItem } from "../TimeLine";
import consultingPageContent from "../../../content/services//consulting/consultingPageContent.json";

const ConsultingPage: React.FC = () => {
  const consultingServices: TimeLineItem[] =
    consultingPageContent.selectedServices;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <Row
        className="rounded-4 my-5 p-4"
        style={{
          backgroundColor: "#F0F0F0",
        }}
      >
        <Col xs={12} md={8} className="order-md-1">
          <h1 className="display-3 text-center mb-4">
            {consultingPageContent.title}
          </h1>
          <ul className="lead" style={{ textAlign: "justify" }}>
            {consultingPageContent.descriptionItems1.map((item, index) => (
              <li key={index} className="mb-3">
                {item}
              </li>
            ))}
          </ul>
        </Col>

        <Col xs={12} className="order-xs-2 order-md-3">
          <ul className="lead" style={{ textAlign: "justify" }}>
            {consultingPageContent.descriptionItems2.map((item, index) => (
              <li key={index} className="mb-3">
                {item}
              </li>
            ))}
          </ul>
        </Col>
        <Col
          xs={12}
          md={4}
          className="text-center d-flex align-items-center justify-content-center order-md-2"
        >
          <Image
            src={`${process.env.PUBLIC_URL}${consultingPageContent.image}`}
            fluid
            rounded
          />
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <h3 className="display-5 mb-4 text-center">
            {consultingPageContent.approachTitle}
          </h3>
          <p className="lead mb-4" style={{ textAlign: "justify" }}>
            {consultingPageContent.approachDescription}
          </p>
          <hr />
        </Col>
      </Row>

      <TimeLine
        title={consultingPageContent.selectedServicesTitle}
        items={consultingServices}
      />
    </Container>
  );
};

export default ConsultingPage;
