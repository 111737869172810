import React from "react";
import { Link } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";

const ServicesDropdown: React.FC = () => {
  return (
    <NavDropdown
      title="Services"
      id="basic-nav-dropdown"
      className="me-3 w-100 navbar-lead"
      align={{ lg: "end" }}
    >
      <NavDropdown.Item
        className="text-center lead"
        as={Link}
        to="/consulting"
        style={{ color: "#79021C" }}
      >
        Consulting services
      </NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item
        className="text-center lead"
        as={Link}
        to="/competence-development"
        style={{ color: "#79021C" }}
      >
        Competence development: training, mentoring and coaching
      </NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item
        className="text-center lead"
        as={Link}
        to="/strategy-development"
        style={{ color: "#79021C" }}
      >
        Strategy, project and programme development, evaluation
      </NavDropdown.Item>
    </NavDropdown>
  );
};

export default ServicesDropdown;
