import React from "react";
import { Card, ListGroup } from "react-bootstrap";

interface ConsultingCardProps {
  header: string;
  listItems: string[];
  footerItems: string[];
}

const ConsultingCard: React.FC<ConsultingCardProps> = ({
  header,
  listItems,
  footerItems,
}) => (
  <Card className="lead h-100 d-flex flex-column">
    <Card.Header className="same-height-header">{header}</Card.Header>
    <ListGroup className="list-group-flush flex-fill">
      {listItems.map((item, index) => (
        <ListGroup.Item key={index}>{item}</ListGroup.Item>
      ))}
    </ListGroup>
    <Card.Footer>
      <small className="text-muted">
        {footerItems.map((item, index) => (
          <div key={index}>{item}</div>
        ))}
      </small>
    </Card.Footer>
  </Card>
);

export default ConsultingCard;
