import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavigationBar from "./components/navigation/NavigationBar";
import Homepage from "./pages/homepage/Homepage";
import ContactPage from "./pages/contact/ContactPage";
import ProductsPage from "./pages/products/ProductsPage";
import ConsultingPage from "./pages/services/consulting/ConsultingPage";
import AboutMePage from "./pages/bio/AboutMePage";
import CompetenceDevelopmentPage from "./pages/services/competence/CompetenceDevelopmentPage";
import StrategyDevelopmentPage from "./pages/services/strategy/StrategyDevelopmentPage";
import Footer from "./components/footer/Footer";
import PrivacyPolicyPage from "./pages/legal/privacy-policy/PrivacyPolicyPage";
import TermsAndConditionsPage from "./pages/legal/terms-and-conditions/TermsAndConditionsPage";
import DisclaimerPage from "./pages/legal/disclaimer/DisclaimerPage";

function App() {
  return (
    <Router>
      <NavigationBar />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/products" element={<ProductsPage />} />
        <Route path="/consulting" element={<ConsultingPage />} />
        <Route
          path="/competence-development"
          element={<CompetenceDevelopmentPage />}
        />
        <Route
          path="/strategy-development"
          element={<StrategyDevelopmentPage />}
        />
        <Route path="/about-me" element={<AboutMePage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route
          path="/terms-and-conditions"
          element={<TermsAndConditionsPage />}
        />
        <Route path="/disclaimer" element={<DisclaimerPage />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
