import React from "react";
import ProductItem from "./ProductItem";

interface Product {
  icon: React.ReactNode;
  url: string;
  label: string;
}

interface ProductListProps {
  items: Product[];
}

const ProductList: React.FC<ProductListProps> = ({ items }) => {
  const sortedItems = items.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <ul className="list-unstyled">
      {sortedItems.map((item, index) => (
        <ProductItem key={index} icon={item.icon} url={item.url} label={item.label} />
      ))}
    </ul>
  );
};

export default ProductList;
