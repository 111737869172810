import React from "react";
import { Row, Col } from "react-bootstrap";
import { FaCogs, FaGlobeAfrica, FaGlobe, FaTasks } from "react-icons/fa";
import ServiceItem from "./ServiceItem";
import servicesData from "../../content/main-page/servicesOffered.json";

const ServicesOffered: React.FC = () => {
  return (
    <Row
      className="rounded-4 justify-content-center p-4"
      style={{
        backgroundColor: "#79021C",
        color: "white",
      }}
      id="services-offered"
    >
      <Col
        md="4"
        className="position-relative column-divider-right column-divider-bottom"
      >
        <h2 className="mt-4">
          <FaCogs className="mr-2" /> {servicesData.services.title}
        </h2>
        <ul className="list-unstyled mt-3">
          {servicesData.services.items.map((item, index) => (
            <ServiceItem key={index} to={item.to} label={item.label} />
          ))}
        </ul>
      </Col>
      <Col md="4" className="position-relative px-4">
        <h2 className="my-4">
          <FaTasks className="mr-2" /> {servicesData.areas.title}
        </h2>
        <ul>
          {servicesData.areas.items.map((area, index) => (
            <li key={index} className="lead mb-3">
              {area}
            </li>
          ))}
        </ul>
      </Col>
      <Col
        md="4"
        className="position-relative column-divider-left column-divider-top px-4"
      >
        <h2 className="my-4">
          <FaGlobeAfrica className="mr-2" /> {servicesData.focus.title}
        </h2>
        <p className="lead">{servicesData.focus.description}</p>
        <h2>
          <FaGlobe className="mr-2" /> {servicesData.languages.title}
        </h2>
        <ul>
          {servicesData.languages.items.map((language, index) => (
            <li key={index} className="lead mb-3">
              {language}
            </li>
          ))}
        </ul>
      </Col>
    </Row>
  );
};

export default ServicesOffered;
