import React from "react";
import { Row, Col } from "react-bootstrap";

interface SectionProps {
  title: string;
  children: React.ReactNode;
}

const Section: React.FC<SectionProps> = ({ title, children }) => {
  return (
    <Row className="mb-4 p-4 rounded-4" style={{ backgroundColor: "#F0F0F0" }}>
      <Col xs={"12"}>
        <h3 className="display-4">{title}</h3>
        <hr />
        {children}
      </Col>
    </Row>
  );
};

export default Section;
