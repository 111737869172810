import React from "react";
import "./ProfileHeader.css";
import profileHeaderData from "../../content/bio/profileHeader.json";

interface ProfileHeaderProps {
  name: string;
}

const ProfileHeader: React.FC<ProfileHeaderProps> = ({ name }) => {
  return (
    <div className="row text-center rounded-4 profile-header p-4 mt-5 d-flex align-items-center justify-content-center">
      <div className="col-md-4">
        <img
          className="rounded-circle img-fluid profile-picture"
          src={`${process.env.PUBLIC_URL}${profileHeaderData.profilePicture}`}
          alt={`${name}'s profile`}
        />
      </div>
      <div className="col-md-8 text-justify">
        <h2 className="display-5 text-center">{name}</h2>
        {profileHeaderData.description.map((paragraph, index) => (
          <p key={index} className="lead">
            {paragraph}
          </p>
        ))}
      </div>
    </div>
  );
};

const ProfileHeaderContainer: React.FC = () => {
  return <ProfileHeader name={profileHeaderData.name} />;
};

export default ProfileHeaderContainer;
