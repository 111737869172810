import React from "react";
import { Link } from "react-router-dom";

interface ServiceItemProps {
  to: string;
  label: string;
}

const ServiceItem: React.FC<ServiceItemProps> = ({ to, label }) => {
  return (
    <li className="mb-3">
      <Link to={to} className="lead" style={{ color: "white" }}>
        {label}
      </Link>
    </li>
  );
};

export default ServiceItem;
