// AboutMePage.tsx
import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import ProfileHeader from "./ProfileHeader";
import ProfileDetails from "./ProfileDetails";
import AdditionalQualifications from "./AdditionalQualifications";
import RequestCV from "./RequestCV";
import "./AboutMePage.css";

const AboutMePage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <ProfileHeader />
      <Row
        className="rounded-4 p-4 mt-5 mb-5"
        style={{ backgroundColor: "#F0F0F0" }}
      >
        <ProfileDetails />
        <AdditionalQualifications />
      </Row>
      <RequestCV />
    </Container>
  );
};

export default AboutMePage;
