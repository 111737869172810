import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import "./Homepage.css";
import MultiCarousel from "./MultiCarousel";
import TopSection from "./TopSection";
import ServicesOffered from "./ServicesOffered";
import ClientOpinions from "./ClientOpinions";

const Homepage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <TopSection />
      <ServicesOffered />
      <MultiCarousel />
      <ClientOpinions />
    </Container>
  );
};

export default Homepage;
