// ContactPage.tsx
import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import ContactHeader from "./ContactHeader";
import ContactMethod from "./ContactMethod";

const ContactPage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container className="my-5">
      <ContactHeader />
      <Row
        className="rounded-4 d-flex align-items-center justify-content-center p-4"
        style={{ backgroundColor: "#F0F0F0", color: "white" }}
      >
        <ContactMethod type="email" />
        <hr style={{ color: "#79021C", maxWidth: "80%" }} />
        <ContactMethod type="linkedin" />
      </Row>
    </Container>
  );
};

export default ContactPage;
