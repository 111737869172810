// TimeLine.tsx
import React from "react";
import { Row, Col } from "react-bootstrap";

export interface TimeLineItem {
  yearStart?: string;
  yearEnd?: string;
  description: string;
}

interface TimeLineProps {
  title: string;
  items: TimeLineItem[];
}

const TimeLine: React.FC<TimeLineProps> = ({ title, items }) => {
  return (
    <Row className="mb-4" style={{ textAlign: "justify" }}>
      <Col xs={12} className="text-center mb-5">
        <h3 className="display-5">{title}</h3>
      </Col>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          {index % 2 === 0 && (
            <>
              <Col xs={6} sm={5} className="py-4">
                <div
                  className="rounded-4 p-4 lead"
                  style={{
                    backgroundColor: "#79021C",
                    color: "white",
                  }}
                >
                  <p>{item.description}</p>
                </div>
              </Col>
              <Col
                xs={1}
                sm={1}
                className="border-end d-none d-sm-flex align-items-center"
              >
                <hr className="custom-hr" />
              </Col>
              <Col
                className="p-4 border-start d-flex align-items-center lead"
                xs={6}
              >
                <p>
                  <b>{item.yearStart}</b>
                </p>
              </Col>
              {item.yearEnd && (
                <Col
                  className="p-4 border-end d-flex flex-column justify-content-center align-items-end text-end lead"
                  xs={6}
                >
                  <b>{item.yearEnd}</b>
                </Col>
              )}
              {item.yearEnd && (
                <Col
                  xs={1}
                  sm={1}
                  className="border-start d-none d-sm-flex align-items-center"
                >
                  <hr className="custom-hr" />
                </Col>
              )}
            </>
          )}
          {index % 2 !== 0 && (
            <>
              <Col
                className="p-4 border-end d-flex flex-column justify-content-center align-items-end text-end lead"
                xs={6}
              >
                <b>{item.yearEnd}</b>
              </Col>
              <Col
                xs={1}
                sm={1}
                className="border-start d-none d-sm-flex align-items-center"
              >
                <hr className="custom-hr" />
              </Col>
              <Col xs={6} sm={5} className="py-4 ms-auto">
                <div
                  className="rounded-4 p-4 lead"
                  style={{
                    backgroundColor: "#79021C",
                    color: "white",
                  }}
                >
                  <p>{item.description}</p>
                </div>
              </Col>
            </>
          )}
        </React.Fragment>
      ))}
    </Row>
  );
};

export default TimeLine;
