import React, { useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import competenceDevelopment from "../../../content/services/competence/competenceDevelopment.json";
import "./CompetenceDevelopmentPage.css";
import ConsultingCard from "../ConsultingCard";

const CompetenceDevelopmentPage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <Row
        className="rounded-4 my-5 p-4"
        style={{
          backgroundColor: "#F0F0F0",
        }}
      >
        <Col xs={12} lg={8}>
          <h1 className="display-3 text-center">
            {competenceDevelopment.title}
          </h1>
          <ul className="lead" style={{ textAlign: "justify" }}>
            {competenceDevelopment.intro.map((item, index) => (
              <li key={index} className="mb-3">
                {item}
              </li>
            ))}
          </ul>
        </Col>
        <Col xs={12} lg={4} className="text-center p-4">
          <Image
            src={`${process.env.PUBLIC_URL}/static/images/20180728_161149.jpg`}
            fluid
            rounded
          />
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <h3 className="display-5 mb-4 text-center">
            {competenceDevelopment.approachTitle}
          </h3>
          <p className="lead mb-4" style={{ textAlign: "justify" }}>
            {competenceDevelopment.approachContent}
          </p>
          <hr />
        </Col>
      </Row>

      <Row className="mb-5" style={{ textAlign: "justify" }}>
        <Col xs={12} className="text-center mb-5">
          <h3 className="display-5">{competenceDevelopment.measuresTitle}</h3>
        </Col>
        {competenceDevelopment.measures.map((measure, index) => (
          <Col key={index} xs={12} lg={4} className="mb-5">
            <ConsultingCard
              header={measure.header}
              listItems={measure.listItems}
              footerItems={measure.footerItems}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default CompetenceDevelopmentPage;
