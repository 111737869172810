import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { FaRegEnvelope } from "react-icons/fa";

const Footer: React.FC = () => {
  return (
    <footer className="text-center py-4 mt-5 mt-auto" id="footer">
      <div style={{ clear: "both" }}></div>
      <div
        className="container"
        style={{
          color: "black",
        }}
      >
        <p className="mb-3 lead">
          Get in contact:{" "}
          <a
            href="mailto:haraldrettner@gmail.com?subject="
            className="mb-3"
            style={{
              textDecoration: "none",
              color: "black",
            }}
          >
            <FaRegEnvelope /> haraldrettner(at)gmail.com
          </a>
        </p>
        <p className="mb-0">
          <Link style={{ color: "#79021C" }} to="/privacy-policy">
            Privacy Policy
          </Link>{" "}
          |{" "}
          <Link style={{ color: "#79021C" }} to="/terms-and-conditions">
            Terms of Service (Terms and Conditions)
          </Link>{" "}
          |{" "}
          <Link style={{ color: "#79021C" }} to="/disclaimer">
            Disclaimer
          </Link>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
