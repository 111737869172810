import React from "react";
import { Carousel, Row, Col } from "react-bootstrap";
import CarouselItem from "./CarouselItem";
import carouselItemsData from "../../content/main-page/carouselItems.json";
import "./MultiCarousel.css";

const MultiCarousel: React.FC = () => {
  return (
    <Row className="justify-content-center mt-4 p-4">
      <Col xs={12} className="text-center">
        <Col xs={12} className="text-center mb-4">
          <h2 className="display-5 mb-4">Partners and Clients</h2>
          <hr />
        </Col>
      </Col>
      <Col xs={12}>
        <Carousel interval={2000}>
          {carouselItemsData.carouselItems.map((items, index) => (
            <Carousel.Item key={index}>
              <Row className="justify-content-center">
                {items.map((item, subIndex) => (
                  <CarouselItem key={subIndex} {...item} />
                ))}
              </Row>
            </Carousel.Item>
          ))}
        </Carousel>
      </Col>
    </Row>
  );
};

export default MultiCarousel;
