// ContactMethod.tsx
import React from "react";
import { Col, Image } from "react-bootstrap";
import { FaRegEnvelope, FaLinkedin } from "react-icons/fa";

interface ContactMethodProps {
  type: "email" | "linkedin";
}

const ContactMethod: React.FC<ContactMethodProps> = ({ type }) => {
  return type === "email" ? (
    <>
      <Col xs={12} sm={8} className="mb-4 mb-sm-0 text-center">
        <h2 style={{ color: "#79021C" }} className="display-6 d-inline">
          Send an Email:
        </h2>
        <a
          href="mailto:haraldrettner@gmail.com?subject="
          className="d-inline"
          style={{
            fontSize: "1.2rem",
            textDecoration: "none",
            color: "#79021C",
            marginLeft: "10px",
          }}
        >
          <FaRegEnvelope /> haraldrettner(at)gmail.com
        </a>
      </Col>
      <Col
        xs={12}
        sm={4}
        className="mb-4 mb-sm-0 p-4 text-center text-sm-start"
      >
        <Image
          src={`${process.env.PUBLIC_URL}/static/images/undraw_mobile_inbox.svg`}
          fluid
        />
      </Col>
    </>
  ) : (
    <>
      <Col xs={12} sm={4} className="mb-4 mb-sm-0 p-4 text-center text-sm-end">
        <Image
          src={`${process.env.PUBLIC_URL}/static/images/undraw_social_growth.svg`}
          fluid
        />
      </Col>
      <Col
        xs={12}
        sm={8}
        className="mb-4 mb-sm-0 p-4 text-center flex-column-reverse"
      >
        <h2 style={{ color: "#79021C" }} className="display-6 d-inline">
          Find me on LinkedIn:
        </h2>
        <a
          href="https://linkedin.com/in/harald-rettner-466926b6?trk=people-guest_people_search-card"
          target="_blank"
          rel="noopener noreferrer"
          className="d-inline"
          style={{
            fontSize: "1.2rem",
            textDecoration: "none",
            color: "#79021C",
            marginLeft: "10px",
          }}
        >
          <FaLinkedin /> LinkedIn Profile
        </a>
      </Col>
    </>
  );
};

export default ContactMethod;
