import React from "react";
import { Row, Col } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import clientOpinionsData from "../../content/main-page/clientOpinions.json";

const ClientOpinions: React.FC = () => {
  return (
    <Row
      className="rounded-4 justify-content-center my-4 p-4"
      id="client-opinions"
      style={{
        backgroundColor: "#F0F0F0",
        color: "black",
      }}
    >
      <Col xs={12} className="text-center mb-4">
        <h4 className="display-6">{clientOpinionsData.title}</h4>
      </Col>
      {clientOpinionsData.opinions.map((opinion, index) => (
        <React.Fragment key={index}>
          {index % 2 === 0 && opinion.image && (
            <Col xs={12} sm={2}>
              <Image
                src={`${process.env.PUBLIC_URL}${opinion.image}`}
                fluid
                className="d-flex align-items-center justify-content-center"
                width={"100%"}
              />
            </Col>
          )}
          <Col xs={12} sm={opinion.image ? 10 : 12} className="mb-4">
            <p className="lead">"{opinion.quote}"</p>
            <p>- {opinion.author}</p>
          </Col>
          {index % 2 === 0 && (
            <Col xs={12}>
              <hr />
            </Col>
          )}
          {index % 2 !== 0 && opinion.image && (
            <Col
              xs={12}
              sm={2}
              className="d-flex align-items-center justify-content-center"
            >
              <Image
                src={`${process.env.PUBLIC_URL}${opinion.image}`}
                fluid
                width={"50%"}
              />
            </Col>
          )}
        </React.Fragment>
      ))}
    </Row>
  );
};

export default ClientOpinions;
