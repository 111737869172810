import React from "react";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import ServicesDropdown from "./ServicesDropdown";

const NavLinks: React.FC = () => {
  return (
    <>
      <Nav.Link as={Link} to="/" className="nav-bar-link me-2 navbar-lead">
        Home
      </Nav.Link>
      <ServicesDropdown />
      <Nav.Link
        as={Link}
        to="/about-me"
        className="nav-bar-link me-2 navbar-lead"
      >
        Bio
      </Nav.Link>
      <Nav.Link
        as={Link}
        to="/products"
        className="nav-bar-link me-2 navbar-lead"
      >
        Products
      </Nav.Link>
      <Nav.Link as={Link} to="/contact" className="nav-bar-link navbar-lead">
        Contact
      </Nav.Link>
    </>
  );
};

export default NavLinks;
