import React from "react";
import { Col } from "react-bootstrap";

interface CarouselItemProps {
  href?: string;
  src: string;
  alt: string;
}

const CarouselItem: React.FC<CarouselItemProps> = ({ href, src, alt }) => {
  const content = (
    <div className="carousel-image-wrapper">
      <img className="carousel-image" src={`${process.env.PUBLIC_URL}${src}`} alt={alt} style={{ cursor: href ? "pointer" : "default" }} />
    </div>
  );

  return (
    <Col xs={12} lg={6} className="d-flex justify-content-center align-items-center p-4">
      {href ? (
        <a href={href} target="_blank" rel="noopener noreferrer">
          {content}
        </a>
      ) : (
        content
      )}
    </Col>
  );
};

export default CarouselItem;
