import React from "react";
import { Col } from "react-bootstrap";
import { FaUserGraduate } from "react-icons/fa";
import qualificationsData from "../../content/bio/additionalQualifications.json";

const AdditionalQualifications: React.FC = () => {
  return (
    <Col xs={12}>
      <h2 className="display-5 mt-2 mb-4">{qualificationsData.title}</h2>
      <ul className="list-unstyled">
        {qualificationsData.qualifications.map((qualification, index) => (
          <li key={index} className="list-item mb-3 lead">
            <FaUserGraduate className="list-item-icon" />
            {qualification.text}
          </li>
        ))}
      </ul>
    </Col>
  );
};

export default AdditionalQualifications;
