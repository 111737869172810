import React from "react";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Button from "../../components/buttons/Button";
import Image from "react-bootstrap/Image";
import topSectionContent from "../../content/main-page/topSection.json";

const TopSection: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/about-me");
  };

  const scrollToId = (id: string): void => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Row className="rounded-4 mt-5 mb-5 p-4" id="top-section">
      <Col sm={8} className="p-sm-4">
        <Row>
          <Col xs={12}>
            <h2 className="display-5" style={{ color: "#79021C" }}>
              {topSectionContent.name}
            </h2>
            <h1
              className="display-5"
              style={{ color: "#79021C", fontWeight: "normal" }}
            >
              {topSectionContent.company}
            </h1>
            <p className="lead" style={{ color: "#79021C" }}>
              <strong>{topSectionContent.specialization}</strong>
            </p>
          </Col>
          <Col xs={12} sm={6} className="text-center">
            <Button
              buttonType="secondary"
              className="mt-4 p-3"
              onClick={handleNavigate}
              label="About Me"
              minWidth="w-100"
            />
          </Col>
          <Col xs={12} sm={6} className="text-center">
            <Button
              buttonType="primary"
              className="mt-4 p-3"
              onClick={() => {
                window.scrollTo(0, 0);
                scrollToId("quote");
              }}
              label="My Services"
              minWidth="w-100"
            />
          </Col>
        </Row>
      </Col>
      <Col sm={4} className="text-center p-5">
        <Image
          className="img-fluid rounded-circle"
          src={`${process.env.PUBLIC_URL}${topSectionContent.profilePicture}`}
          fluid
        />
      </Col>
      <hr />
      <Col xs={12} className="text-center mt-4">
        <Image
          src={`${process.env.PUBLIC_URL}${topSectionContent.quoteImage}`}
          fluid
          width={"20vw"}
          className="mb-3"
        />
        <Image
          src={`${process.env.PUBLIC_URL}${topSectionContent.quoteImage}`}
          fluid
          width={"20vw"}
          className="mb-3"
        />
        <h5 style={{ color: "#79021C" }}>
          {topSectionContent.quote} <br />
        </h5>
        <p className="lead" style={{ color: "#79021C" }} id="quote">
          <strong>{topSectionContent.author}</strong>
        </p>
      </Col>
    </Row>
  );
};

export default TopSection;
