import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Brand from "./Brand";
import NavLinks from "./NavLinks";
import "./NavigationBar.css";

const NavigationBar: React.FC = () => {
  return (
    <Navbar collapseOnSelect expand="md" sticky="top" id="nav-bar">
      <Container>
        <Brand />
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          className="custom-toggler"
        />
        <Navbar.Collapse id="responsive-navbar-nav" className="text-center">
          <Nav className="me-auto" />
          <Nav>
            <NavLinks />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
