import React from "react";
import { Col, Row } from "react-bootstrap";
import contactLinksData from "../../content/contact/contactLinks.json";

const ContactHeader: React.FC = () => {
  return (
    <Row
      className="rounded-4 justify-content-md-center mb-4 p-4"
      style={{ backgroundColor: "#79021C", color: "white" }}
    >
      <Col className="text-center">
        <h1 className="display-5">Get in Touch</h1>
        <p className="lead">
          Please feel free to send me an{" "}
          <a
            href={contactLinksData.email.href}
            className="d-inline"
            style={{
              fontSize: "1.2rem",
              color: "white",
            }}
          >
            Email
          </a>{" "}
          or connect with me on{" "}
          <a
            href={contactLinksData.linkedin.href}
            target="_blank"
            rel="noopener noreferrer"
            className="d-inline"
            style={{
              fontSize: "1.2rem",
              color: "white",
            }}
          >
            LinkedIn
          </a>
          .
        </p>
      </Col>
    </Row>
  );
};

export default ContactHeader;
