import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import privacyPolicyContent from "../../../content/legal/privacyPolicyContent.json";

const PrivacyPolicyPage: React.FC = () => {
  return (
    <Container className="p-4">
      <Row className="justify-content-center">
        <Col>
          <h1 className="display-5">{privacyPolicyContent.title}</h1>
          {privacyPolicyContent.sections.map((section, index) => (
            <React.Fragment key={index}>
              <h2 className="lead">{section.heading}</h2>
              <p
                className="lead"
                dangerouslySetInnerHTML={{ __html: section.content }}
              ></p>
            </React.Fragment>
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyPolicyPage;
