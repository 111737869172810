// RequestCV.tsx
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FaRegEnvelope } from 'react-icons/fa';

const RequestCV: React.FC = () => {
  return (
    <Row
      className="mb-4 p-4 rounded-4 text-center"
      style={{ backgroundColor: "#79021C", color: "white" }}
    >
      <Col>
        <h3 className="display-6">Request CV</h3>
        <p className="lead">
          Request a full CV by sending an email to:{" "}
          <a
            href="mailto:haraldrettner@gmail.com?subject="
            className="mb-3"
            style={{
              fontSize: "1.5rem",
              textDecoration: "none",
              color: "white",
            }}
          >
            <FaRegEnvelope /> haraldrettner(at)gmail.com
          </a>
        </p>
      </Col>
    </Row>
  );
};

export default RequestCV;
